import { graphql, PageProps } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import * as React from "react";

import { Layout } from "@/components/Layout";
import { PostListItem } from "@/components/PostListItem";

const PostListByTagPage: React.FC<PageProps<Queries.TagPageQuery>> = ({
  data,
}) => {
  const tag = data.contentfulTag?.name;
  const title = tag + "の投稿一覧";

  return (
    <Layout>
      <GatsbySeo title={title} />
      <div className="prose pt-12 pb-8">
        <h1 className="md:text-6xl">{title}</h1>
      </div>
      <ul className="list-none">
        {data.allContentfulPost.nodes.map((props) => (
          <PostListItem {...props} />
        ))}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query TagPage($contentful_id: String) {
    contentfulTag(contentful_id: { eq: $contentful_id }) {
      name
    }
    allContentfulPost(
      filter: {
        metadata: {
          tags: { elemMatch: { contentful_id: { eq: $contentful_id } } }
        }
      }
    ) {
      nodes {
        ...PostListItemProps
      }
    }
  }
`;

export default PostListByTagPage;
